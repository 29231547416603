import React from 'react';
import { graphql } from 'gatsby';
import { Detail, Seo } from 'components';

export default ({
  data: {
    page: {
      frontmatter: { title, announce, tags, head_bg, announce_bg, og, description },
      body
    }
  }
}) => {
  return (
    <>
      <Seo title={title + ' | плакат Nimax'} og={og} description={description} />
      <Detail
        title={title}
        announce={announce}
        tags={tags ? tags.split(',') : []}
        announceBg={announce_bg}
        headBg={head_bg}
      >
        {body}
      </Detail>
    </>
  );
};

export const pageQuery = graphql`
  query($id: String!) {
    page: mdx(id: {eq: $id}) {
      frontmatter {
        title
        redirect
        announce
        tags
        announce_bg
        og
        description
      }
      body
    }
  }
`;
